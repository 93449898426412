<template>
	<div class="selectMouth">
		<van-popup v-model="show" position="bottom" :style="{ height: '30%' }">
			<van-datetime-picker v-model="currentDate" type="year-month" title="选择年月" :min-date="minDate"
				:max-date="maxDate" @confirm='confirm' @cancel='show = false' />
		</van-popup>
	</div>
</template>

<script>
	import {
		Popup,
		DatetimePicker
	} from 'vant';


	export default {
		name: "SelectMouth",
		data() {
			return {
				show: false,
				minDate: new Date(new Date().getFullYear()-1, new Date().getMonth(), 1),
				maxDate: new Date(),
				currentDate: new Date(),
			}
		},
		components: {
			[Popup.name]: Popup,
			[DatetimePicker.name]: DatetimePicker
		},
		methods: {
			// 当前选中的时间
			confirm(value) {
				let time = this.$moment(new Date(value)).format("YYYY-MM")
				this.$emit('timeChange',time)
				this.show = false
			},
		}
	}
</script>

<style lang="less" scoped>


</style>
